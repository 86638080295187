import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/seo'

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <SEO title="404: Ikke Fundet" />
        <h1>Ikke Fundet</h1>
        <p>Det var ikke muligt at finde det du ledte efter..</p>
      </Layout>
    )
  }
}

export default NotFoundPage
